import React from 'react'
import {Block} from 'baseui/block'
import {Check, CheckIndeterminate} from 'baseui/icon'
import {ARTWORK_SIZES, ListItem} from 'baseui/list'
import {LabelLarge, ParagraphMedium} from 'baseui/typography'

const padding = (theme, scale) => ({
  paddingBottom: theme.sizing[scale],
  paddingLeft: theme.sizing[scale],
  paddingRight: theme.sizing[scale],
  paddingTop: theme.sizing[scale],
})

const renderObjectives = (objectives, results) => {
  return objectives.map((objective, index) => (
    <ListItem
      key={objective}
      overrides={{
        ArtworkContainer: {
          style: ({$theme}) => ({
            paddingLeft: $theme.sizing.scale400,
            paddingRight: $theme.sizing.scale400,
            color: results[index] ? $theme.colors.positive500 : $theme.colors.mono600,
          }),
        },
        Content: {
          style: ({$theme}) => ({
            borderBottomWidth: 0,
            color: $theme.colors.primary700,
            height: 'auto',
            paddingBottom: $theme.sizing.scale200,
            paddingTop: $theme.sizing.scale200,
          }),
        },
      }}
      artwork={props => (results[index] ? <Check {...props} /> : <CheckIndeterminate {...props} />)}
      artworkSize={ARTWORK_SIZES.MEDIUM}
    >
      <ParagraphMedium
        overrides={{
          Block: {
            style: ({$theme}) => ({
              color: $theme.colors.primary700,
              marginTop: 0,
              marginBottom: 0,
            }),
          },
        }}
      >
        {objective}
      </ParagraphMedium>
    </ListItem>
  ))
}

const StatsObjectives = ({objectives, results}) => {
  return (
    <Block
      overrides={{
        Block: {
          style: ({$theme}) => ({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            ...padding($theme, 'scale400'),
          }),
        },
      }}
    >
      <LabelLarge
        overrides={{
          Block: {
            style: ({$theme}) => ({
              color: $theme.colors.mono600,
            }),
          },
        }}
      >
        Objectives
      </LabelLarge>
      <Block
        $as="ul"
        overrides={{
          Block: {
            style: {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              paddingLeft: 0,
            },
          },
        }}
      >
        {renderObjectives(objectives, results)}
      </Block>
    </Block>
  )
}

export default StatsObjectives
