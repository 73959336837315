import React from 'react'

import {Block} from 'baseui/block'
import {Input} from 'baseui/input'

const InputList = ({list, onChange, placeholder}) => {
  return (
    <Block
      overrides={{
        Block: {
          style: ({$theme}) => ({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '50%',
            marginLeft: '25%',
            marginTop: '20px',
          }),
        },
      }}
    >
      {list.map((item, idx) => {
        return (
          <Input
            key={`item${idx}`}
            value={item}
            onChange={e => {
              onChange(idx, e.target.value, e)
            }}
            placeholder={placeholder}
            overrides={{
              Root: {
                style: ({$theme}) => ({
                  marginTop: '2px',
                  marginBottom: '2px',
                }),
              },
            }}
          />
        )
      })}
    </Block>
  )
}

export default InputList
