import {Block} from 'baseui/block'
import {Card} from 'baseui/card'
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid'
import React from 'react'
import StatsChallenges from './stats-challenges'
import StatsHeader from './stats-header'
import StatsObjectives from './stats-objectives'
import StatsProgress from './stats-progress'
import StatsTips from './stats-tips'

const StudentStats = ({course, student}) => {
  const currentChapterIndex = student.chapters.findIndex(c => c.status === 'active')
  const currentChapter = student.chapters[currentChapterIndex]

  return (
    <Block
      overrides={{
        Block: {
          style: ({$theme}) => ({
            marginBottom: $theme.sizing.scale800,
            maxWidth: '60rem',
          }),
        },
      }}
    >
      <Card>
        <StatsHeader
          title={course.chapters[currentChapterIndex].title}
          description={course.chapters[currentChapterIndex].desc}
        />
        <StatsProgress progress={currentChapter.completed} successValue={course.chapters.length} />
        <FlexGrid
          flexGridColumnCount={[1, 1, 2, 2]}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
        >
          <FlexGridItem
            overrides={{
              Block: {
                style: {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'stretch',
                  justifyContent: 'start',
                },
              },
            }}
          >
            <StatsChallenges
              challenges={course.chapters[currentChapterIndex].challenges.length}
              completed={currentChapter.completed}
              duration={currentChapter.duration}
            />
          </FlexGridItem>
          <FlexGridItem
            overrides={{
              Block: {
                style: {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'stretch',
                  justifyContent: 'start',
                },
              },
            }}
          >
            <StatsObjectives
              objectives={course.chapters[currentChapterIndex].objectives}
              results={currentChapter.objectivesResults}
            />
          </FlexGridItem>
        </FlexGrid>
        <StatsTips tips={course.chapters[currentChapterIndex].tips} />
      </Card>
    </Block>
  )
}

export default StudentStats
