import {Block} from 'baseui/block'
import {Button} from 'baseui/button'
import {Heading, HeadingLevel} from 'baseui/heading'
import {KIND} from 'baseui/notification'
import {ParagraphSmall} from 'baseui/typography'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import LoadingSpinner from '../../../components/app/loading-spinner'
import {AuthLayout} from '../../../components/layout'
import {routes} from '../../../utils/config'
import {setLoading} from '../../persistence/app-slice'
import {auth} from '../../persistence/firebase'
import {setNotification} from '../../persistence/notification-slice'

const VerifyEmail = ({params}) => {
  const dispatch = useDispatch()
  const {loading} = useSelector(state => state.app)
  const [status, setStatus] = useState(false)

  useEffect(() => {
    dispatch(setLoading({loading: true}))
    // Try to apply the email verification code.
    auth
      .applyActionCode(params.oobCode)
      .then(() => {
        setStatus(true)
      })
      .catch(error => {
        // Code is invalid or expired. Ask the user to verify their email address again.
        dispatch(setNotification({code: error.code, message: error.message, kind: KIND.negative}))
        setStatus(false)
      })
      .finally(() => {
        dispatch(setLoading({loading: false}))
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return (
      <AuthLayout>
        <Block display="flex" alignItems="center" justifyContent="center">
          <LoadingSpinner />
        </Block>
      </AuthLayout>
    )
  }

  if (!loading && !status) {
    return (
      <AuthLayout>
        <Block $as="header">
          <HeadingLevel>
            <Heading styleLevel={3}>Verification Failed</Heading>
          </HeadingLevel>
        </Block>
        <Block>
          <ParagraphSmall>
            Email address could not be verified. The incident has been reported. Please contact
            support for further assistance.
          </ParagraphSmall>
        </Block>
        <Button
          isLoading={loading}
          disabled={loading}
          $as={Link}
          to={routes.home.url}
          overrides={{
            BaseButton: {
              style: ({$theme}) => ({
                marginTop: $theme.sizing.scale400,
              }),
            },
          }}
        >
          {routes.home.name}
        </Button>
      </AuthLayout>
    )
  }

  return (
    <AuthLayout>
      <Block $as="header">
        <HeadingLevel>
          <Heading styleLevel={3}>Verify Email</Heading>
        </HeadingLevel>
      </Block>
      <Block>
        <ParagraphSmall>Email address has been verified.</ParagraphSmall>
      </Block>
      <Button
        isLoading={loading}
        disabled={loading}
        $as={Link}
        to={routes.home.url}
        overrides={{
          BaseButton: {
            style: ({$theme}) => ({
              marginTop: $theme.sizing.scale400,
            }),
          },
        }}
      >
        {routes.home.name}
      </Button>
    </AuthLayout>
  )
}

export default VerifyEmail
