import {Block} from 'baseui/block'
import {Button, SHAPE} from 'baseui/button'
import {ParagraphMedium} from 'baseui/typography'
import React from 'react'
import {Link} from 'react-router-dom'
import {EmptyLayout} from '../../components/layout'
import {assets, routes} from '../../utils/config'

const NoMatchWithoutAuth = () => (
  <EmptyLayout>
    <Block width="100%">
      <Block
        as="img"
        src={assets.logoByLRF.location}
        overrides={{
          Block: {
            style: ({$theme}) => ({
              height: '2.5rem',
              paddingLeft: $theme.sizing.scale500,
              paddingRight: $theme.sizing.scale500,
              paddingTop: $theme.sizing.scale500,
              paddingBottom: $theme.sizing.scale500,
              [$theme.media.small]: {
                height: '4rem',
              },
              [$theme.media.large]: {
                height: '5rem',
              },
            }),
          },
        }}
      />
    </Block>
    <Block>
      <ParagraphMedium
        overrides={{
          Block: {
            style: ({$theme}) => ({
              color: $theme.colors.primary50,
              fontSize: 1.4 + 'rem',
              fontWeight: 900,
              lineHeight: 1.8 + 'rem',
              marginTop: $theme.sizing.scale0,
              textAlign: 'center',
              width: 16 + 'rem',
              [$theme.media.small]: {
                marginTop: $theme.sizing.scale400,
                fontSize: 1.8 + 'rem',
                lineHeight: 2.2 + 'rem',
                width: 24 + 'rem',
              },
              [$theme.media.large]: {
                marginTop: $theme.sizing.scale800,
                fontSize: 2.4 + 'rem',
                lineHeight: 3 + 'rem',
                width: 40 + 'rem',
              },
            }),
          },
        }}
      >
        Sorry pal, doesn't look you're in the right place. Try some other door.
      </ParagraphMedium>
      <Block display="flex" justifyContent="center">
        <Button
          $as={Link}
          to={routes.home.url}
          shape={SHAPE.pill}
          overrides={{
            BaseButton: {
              style: ({$theme}) => ({
                background: $theme.colors.primary50,
                color: '#0D837E',
                fontSize: 1.4 + 'rem',
                fontWeight: 900,
                paddingTop: $theme.sizing.scale500,
                paddingBottom: $theme.sizing.scale600,
                paddingLeft: $theme.sizing.scale800,
                paddingRight: $theme.sizing.scale800,
                [$theme.media.small]: {
                  marginTop: $theme.sizing.scale400,
                  fontSize: 1.8 + 'rem',
                  paddingTop: $theme.sizing.scale700,
                  paddingBottom: $theme.sizing.scale800,
                  paddingLeft: $theme.sizing.scale900,
                  paddingRight: $theme.sizing.scale900,
                },
                [$theme.media.large]: {
                  marginTop: $theme.sizing.scale800,
                  fontSize: 2.4 + 'rem',
                  paddingTop: $theme.sizing.scale800,
                  paddingBottom: $theme.sizing.scale900,
                  paddingLeft: $theme.sizing.scale1200,
                  paddingRight: $theme.sizing.scale1200,
                },
                ':hover': {
                  background: '#10B2AE',
                  color: $theme.colors.primary50,
                },
              }),
            },
          }}
        >
          go home
        </Button>
      </Block>
    </Block>
  </EmptyLayout>
)

export default NoMatchWithoutAuth
