import {Block} from 'baseui/block'
import {Button} from 'baseui/button'
import {FormControl} from 'baseui/form-control'
import {Heading, HeadingLevel} from 'baseui/heading'
import {Input} from 'baseui/input'
import {KIND} from 'baseui/notification'
import {ParagraphSmall} from 'baseui/typography'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import LoadingSpinner from '../../../components/app/loading-spinner'
import {AuthLayout} from '../../../components/layout'
import {routes} from '../../../utils/config'
import {validatePasswordMatching} from '../../../utils/validation'
import {setLoading} from '../../persistence/app-slice'
import {auth} from '../../persistence/firebase'
import {setNotification} from '../../persistence/notification-slice'

const ResetPassword = ({params}) => {
  const dispatch = useDispatch()
  const {loading} = useSelector(state => state.app)
  const [accountEmail, setAccountEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmation, setConfirmation] = useState('')
  const [isValidCode, setIsValidCode] = useState(true)
  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false)

  useEffect(() => {
    dispatch(setLoading({loading: true}))
    // Verify the password reset code is valid.
    auth
      .verifyPasswordResetCode(params.oobCode)
      .then(email => {
        // Get the account email address.
        setAccountEmail(email)
      })
      .catch(error => {
        // Invalid or expired action code. Ask user to try to reset the password again.
        setIsValidCode(false)
        dispatch(
          setNotification({
            code: error.code,
            message: error.message,
            kind: KIND.warning,
          }),
        )
      })
      .finally(() => {
        dispatch(setLoading({loading: false}))
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangePassword = event => {
    const {target} = event
    setPassword(target.value)
  }

  const handleChangeConfirmation = event => {
    const {target} = event
    setConfirmation(target.value)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    const validationError = validatePasswordMatching({password, confirmation})

    if (validationError) {
      dispatch(setNotification(validationError))
      return
    }

    dispatch(setLoading({loading: true}))
    try {
      await auth.confirmPasswordReset(params.oobCode, password)
      dispatch(
        setNotification({
          code: 'auth/password-updated',
          message: 'Password reset has been confirmed and new password updated.',
          kind: KIND.positive,
        }),
      )
      dispatch(setLoading({loading: false}))
      setIsPasswordUpdated(true)
    } catch (error) {
      // Error occurred during confirmation. The code might have expired or the password is too weak.
      dispatch(
        setNotification({
          code: error.code,
          message: error.message,
          kind: KIND.negative,
        }),
      )
    }
  }

  const renderInvalidCode = () => (
    <>
      <Block>
        <ParagraphSmall>
          Cannot reset password. The code might have expired. Please, try to reset the password
          again.
        </ParagraphSmall>
      </Block>
      <Button
        isLoading={loading}
        disabled={loading}
        $as={Link}
        to={routes.home.url}
        overrides={{
          BaseButton: {
            style: ({$theme}) => ({
              marginTop: $theme.sizing.scale400,
            }),
          },
        }}
      >
        Back {routes.home.name}
      </Button>
    </>
  )

  const renderUpdatePassword = () => (
    <>
      <Block>
        <ParagraphSmall>Provide a new password for {accountEmail}</ParagraphSmall>
      </Block>
      <Block $as="form" onSubmit={handleSubmit} noValidate>
        <FormControl label="Password">
          <Input type="password" name="password" value={password} onChange={handleChangePassword} />
        </FormControl>
        <FormControl label="Password Confirmation">
          <Input
            type="password"
            name="confirmation"
            value={confirmation}
            onChange={handleChangeConfirmation}
          />
        </FormControl>
        <Button
          isLoading={loading}
          disabled={loading}
          type="submit"
          overrides={{
            BaseButton: {
              style: ({$theme}) => ({
                marginTop: $theme.sizing.scale400,
              }),
            },
          }}
        >
          Update Password
        </Button>
      </Block>
    </>
  )

  const renderPasswordUpdated = () => (
    <>
      <Block>
        <ParagraphSmall>You can now log back in with your new credentials.</ParagraphSmall>
      </Block>
      <Button
        isLoading={loading}
        disabled={loading}
        $as={Link}
        to={routes.login.url}
        overrides={{
          BaseButton: {
            style: ({$theme}) => ({
              marginTop: $theme.sizing.scale400,
            }),
          },
        }}
      >
        {routes.login.name}
      </Button>
    </>
  )

  const renderUI = () => {
    if (isPasswordUpdated) {
      return renderPasswordUpdated()
    }
    if (setAccountEmail && isValidCode) {
      return renderUpdatePassword()
    }
    return renderInvalidCode()
  }

  if (loading) {
    return (
      <AuthLayout>
        <Block display="flex" alignItems="center" justifyContent="center">
          <LoadingSpinner />
        </Block>
      </AuthLayout>
    )
  }

  if (!accountEmail && isValidCode) {
    return null
  }

  return (
    <AuthLayout>
      <Block $as="header">
        <HeadingLevel>
          <Heading styleLevel={3}>Reset Password</Heading>
        </HeadingLevel>
      </Block>
      {renderUI()}
    </AuthLayout>
  )
}

export default ResetPassword
