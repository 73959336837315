import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Block} from 'baseui/block'
import {Button, KIND, SHAPE, SIZE} from 'baseui/button'
import {Card} from 'baseui/card'
import {Input} from 'baseui/input'
import {Modal, ModalBody, ModalButton, ModalFooter, ModalHeader, ROLE} from 'baseui/modal'
import {StyledSpinnerNext} from 'baseui/spinner'
import {LabelLarge, LabelSmall} from 'baseui/typography'
import findLastIndex from 'lodash/findLastIndex'
import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {updateStudentData} from '../persistence/student-actions'

const StudentSelector = ({handleNext, student}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [studentName, setStudentName] = useState(student.studentName)
  const {isLoading} = useSelector(state => state.app)
  const dispatch = useDispatch()

  const handleChange = event => {
    const {value} = event.target
    setStudentName(() => value)
  }

  const toggleModal = () => {
    setStudentName(student.studentName)
    setIsOpen(prevState => !prevState)
  }

  const handleSubmit = async () => {
    const prevName = student.studentName
    const newName = studentName.trim()

    if (prevName !== newName) {
      await dispatch(updateStudentData({studentName: newName}, student.id))
      toggleModal()
    }
  }

  const selectNextStudent = () => handleNext(1)

  const selectPrevStudent = () => handleNext(-1)

  const getCurrentChapter = () => {
    const studentStatus = status =>
      findLastIndex(student.data.chapters, chapter => chapter.status === status)
    const activeChapter = studentStatus('active')
    if (activeChapter === -1) {
      return studentStatus('completed')
    }
    return activeChapter
  }

  const isDisabled = () => {
    const prevName = student.studentName
    const newName = studentName.trim()
    return isLoading || prevName === newName || !studentName.trim()
  }

  return (
    <>
      <Block
        overrides={{
          Block: {
            style: ({$theme}) => ({
              marginBottom: $theme.sizing.scale800,
              maxWidth: '60rem',
            }),
          },
        }}
      >
        <Card>
          <Block
            overrides={{
              Block: {
                style: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'stretch',
                },
              },
            }}
          >
            <Button
              kind={KIND.secondary}
              shape={SHAPE.round}
              onClick={selectPrevStudent}
              overrides={{
                BaseButton: {
                  style: {
                    height: '3rem',
                    width: '3rem',
                  },
                },
              }}
            >
              <FontAwesomeIcon icon="arrow-left" />
            </Button>
            <Block
              overrides={{
                Block: {
                  style: {
                    alignItems: 'center',
                    display: 'flex',
                    flex: 1,
                  },
                },
              }}
            >
              <Block
                overrides={{
                  Block: {
                    style: ({$theme}) => ({
                      display: 'none',
                      color: $theme.colors.primary700,
                      [$theme.media.medium]: {
                        display: 'block',
                        paddingBottom: $theme.sizing.scale500,
                        paddingLeft: $theme.sizing.scale500,
                        paddingRight: $theme.sizing.scale500,
                        paddingTop: $theme.sizing.scale500,
                        marginLeft: $theme.sizing.scale800,
                      },
                    }),
                  },
                }}
              >
                <FontAwesomeIcon icon="graduation-cap" size="2x" />
              </Block>
              <Block
                overrides={{
                  Block: {
                    style: ({$theme}) => ({
                      paddingLeft: $theme.sizing.scale400,
                      paddingRight: $theme.sizing.scale400,
                      marginLeft: $theme.sizing.scale800,
                      [$theme.media.medium]: {
                        marginLeft: 0,
                      },
                    }),
                  },
                }}
              >
                <LabelLarge
                  overrides={{
                    Block: {
                      style: ({$theme}) => ({
                        color: $theme.colors.primary700,
                      }),
                    },
                  }}
                >
                  {student?.studentName}
                </LabelLarge>
                <LabelSmall
                  overrides={{
                    Block: {
                      style: ({$theme}) => ({
                        color: $theme.colors.mono600,
                      }),
                    },
                  }}
                >
                  Working on Chapter {getCurrentChapter()}
                </LabelSmall>
              </Block>
              <Button
                kind={KIND.secondary}
                shape={SHAPE.round}
                onClick={toggleModal}
                overrides={{
                  BaseButton: {
                    style: ({$theme}) => ({
                      display: 'none',
                      [$theme.media.small]: {
                        display: 'block',
                        height: '3rem',
                        width: '3rem',
                        marginLeft: $theme.sizing.scale800,
                        marginRight: $theme.sizing.scale800,
                      },
                    }),
                  },
                }}
              >
                <FontAwesomeIcon icon="pen-alt" />
              </Button>
            </Block>
            <Button
              kind={KIND.secondary}
              shape={SHAPE.round}
              onClick={selectNextStudent}
              overrides={{
                BaseButton: {
                  style: {
                    height: '3rem',
                    width: '3rem',
                  },
                },
              }}
            >
              <FontAwesomeIcon icon="arrow-right" />
            </Button>
          </Block>
        </Card>
      </Block>
      <Modal
        onClose={toggleModal}
        isOpen={isOpen}
        role={ROLE.dialog}
        closeable
        animate
        autoFocus
        unstable_ModalBackdropScroll
      >
        <ModalHeader>Set student name</ModalHeader>
        <ModalBody>
          {isLoading ? (
            <Block
              overrides={{
                Block: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                  },
                },
              }}
            >
              <StyledSpinnerNext />
            </Block>
          ) : (
            <Input value={studentName} onChange={handleChange} />
          )}
        </ModalBody>
        <ModalFooter>
          <ModalButton onClick={toggleModal} size={SIZE.compact} kind={KIND.secondary}>
            Cancel
          </ModalButton>
          <ModalButton
            onClick={handleSubmit}
            size={SIZE.compact}
            kind={KIND.primary}
            disabled={isDisabled()}
          >
            Okay
          </ModalButton>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default StudentSelector
