import React from 'react'

import {Block} from 'baseui/block'
import {Card} from 'baseui/card'

const CenteredCard = ({children}) => {
  return (
    <Block
      overrides={{
        Block: {
          style: ({$theme}) => ({
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }),
        },
      }}
    >
      <Card
        overrides={{
          Root: {
            style: ({$theme}) => ({
              width: '30rem',
              minHeight: '35rem',
            }),
          },
          Body: {
            style: ({$theme}) => ({
              width: '100%',
              height: '100%',
            }),
          },
          Contents: {
            style: ({$theme}) => ({
              position: 'relative',
              width: 'calc(100% - 32px)',
              height: 'calc(100% - 32px)',
            }),
          },
        }}
      >
        {children}
      </Card>
    </Block>
  )
}

export default CenteredCard
