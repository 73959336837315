import {Button} from 'baseui/button'
import React from 'react'
import {useHistory} from 'react-router-dom'
import {routes} from '../../utils/config'

const BackButton = () => {
  const history = useHistory()

  const backHandler = event => {
    history.push(routes.roleSelector.url)
  }

  return (
    <Button
      type="submit"
      onClick={backHandler}
      overrides={{
        BaseButton: {
          style: ({$theme}) => ({
            // marginTop: $theme.sizing.scale800,
            width: '10%',
            float: 'left',
            position: 'relative',
            flexBasis: '13%',
            marginRight: '2rem',
            top: '4rem',
          }),
        },
      }}
    >
      Back
    </Button>
  )
}
export default BackButton
