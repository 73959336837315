import React, {useState} from 'react'

import {Block} from 'baseui/block'
import {Button, KIND} from 'baseui/button'
import {ButtonGroup, MODE} from 'baseui/button-group'
import {HeadingMedium} from 'baseui/typography'

const PaginatedContent = ({children, onDone, onChange}) => {
  const [currentPage, setCurrentPage] = useState(0)

  const flexBoxProps = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  }

  const navClicked = (idx, e) => {
    if (currentPage === idx) {
      // same (do nothing)
      return
    } else if (idx >= children.length) {
      // done
      if (onDone) onDone()
    } else if (idx >= 0) {
      // everything else
      if (onChange) onChange(idx)
      setCurrentPage(idx)
    }
  }

  return (
    <Block id="flexy" {...flexBoxProps}>
      <Block
        id="contenty"
        overrides={{
          Block: {
            style: ({$theme}) => ({
              height: '100%',
              width: '90%',
              marginBottom: '30px',
            }),
          },
        }}
      >
        {children.map((child, idx) => {
          return (
            <Block key={`page${idx}`} display={idx === currentPage ? 'initial' : 'none'}>
              {child}
            </Block>
          )
        })}
      </Block>
      <Block id="pages">
        <ButtonGroup
          mode={MODE.radio}
          selected={currentPage}
          overrides={{
            Root: {
              style: ({$theme}) => ({
                marginBottom: '20px',
              }),
            },
          }}
          onClick={(event, index) => {
            navClicked(index, event)
          }}
        >
          {children.map((child, idx) => {
            return (
              <Button
                key={`pb${idx}`}
                overrides={{
                  BaseButton: {
                    style: ({$theme}) => ({
                      borderBottomLeftRadius: '20px',
                      borderBottomRightRadius: '20px',
                      borderTopLeftRadius: '20px',
                      borderTopRightRadius: '20px',
                      marginLeft: '5px',
                      marginRight: '5px',
                      width: '16px',
                      height: '16px',
                      paddingRight: 0,
                      paddingTop: 0,
                      paddingLeft: 0,
                      paddingBottom: 0,
                    }),
                  },
                }}
              ></Button>
            )
          })}
        </ButtonGroup>
      </Block>
      <Block id="nextprev" width="100%">
        <Button
          kind={KIND.secondary}
          overrides={{
            BaseButton: {
              style: ({$theme}) => ({
                width: 'calc(50% - 5px)',
              }),
            },
          }}
          onClick={event => {
            navClicked(currentPage - 1)
          }}
          disabled={currentPage === 0}
        >
          Previous
        </Button>
        <Button
          kind={currentPage === children.length - 1 ? KIND.primary : KIND.secondary}
          overrides={{
            BaseButton: {
              style: ({$theme}) => ({
                width: 'calc(50% - 5px)',
                marginLeft: '10px',
              }),
            },
          }}
          onClick={event => {
            navClicked(currentPage + 1)
          }}
        >
          {currentPage === children.length - 1 ? 'Done' : 'Next'}
        </Button>
      </Block>
    </Block>
  )
}

export const PageTitle = ({children}) => {
  return <HeadingMedium>{children}</HeadingMedium>
}

export const Page = ({title, children}) => {
  return (
    <Block
      overrides={{
        Block: {
          style: ({$theme}) => ({
            textAlign: 'center',
          }),
        },
      }}
    >
      {title ? <PageTitle>{title}</PageTitle> : ''}
      {children}
    </Block>
  )
}

export default PaginatedContent
