import {Block} from 'baseui/block'
import {Button} from 'baseui/button'
import {FormControl} from 'baseui/form-control'
import {Heading, HeadingLevel} from 'baseui/heading'
import {Input} from 'baseui/input'
import {StyledLink} from 'baseui/link'
import {KIND} from 'baseui/notification'
import {ParagraphSmall} from 'baseui/typography'
import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {AuthLayout} from '../../components/layout'
import {routes} from '../../utils/config'
import {isValidEmail} from '../../utils/validation'
import {setLoading} from '../persistence/app-slice'
import {auth} from '../persistence/firebase'
import {setNotification} from '../persistence/notification-slice'

const PasswordReset = ({history}) => {
  const {loading} = useSelector(state => state.app)
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const normalizedEmail = email.trim().toLowerCase()

  const isDisabled = () => {
    return loading || !normalizedEmail
  }

  const handleChangeEmail = event => {
    setEmail(event.target.value)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    const error = isValidEmail(normalizedEmail)
    if (error) {
      return dispatch(
        setNotification({
          code: error.code,
          message: error.message,
          kind: KIND.warning,
        }),
      )
    }

    try {
      dispatch(setLoading({loading: true}))
      await auth.sendPasswordResetEmail(email)
      await history.push(routes.login.url)
    } catch (error) {
      dispatch(
        setNotification({
          code: error.code,
          message: error.message,
          kind: KIND.warning,
        }),
      )
    } finally {
      dispatch(setLoading({loading: false}))
    }
  }

  return (
    <AuthLayout>
      <Block $as="header">
        <HeadingLevel>
          <Heading styleLevel={3}>{routes.passwordReset.name}</Heading>
        </HeadingLevel>
      </Block>
      <Block $as="form" onSubmit={handleSubmit} noValidate>
        <FormControl label="Email">
          <Input
            type="email"
            name="email"
            autoComplete="off"
            value={email}
            onChange={handleChangeEmail}
          />
        </FormControl>
        <Button
          isLoading={loading}
          disabled={isDisabled()}
          type="submit"
          overrides={{
            BaseButton: {
              style: ({$theme}) => ({
                marginTop: $theme.sizing.scale400,
              }),
            },
          }}
        >
          Send Password Reset Email
        </Button>
        <Block
          overrides={{
            Block: {
              style: ({$theme}) => ({
                paddingTop: $theme.sizing.scale800,
              }),
            },
          }}
        >
          <ParagraphSmall>
            Know your password already?{' '}
            <StyledLink $as={Link} to={routes.login.url}>
              Login here
            </StyledLink>
          </ParagraphSmall>
        </Block>
      </Block>
    </AuthLayout>
  )
}

export default PasswordReset
