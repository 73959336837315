import {Block} from 'baseui/block'
import {Plus} from 'baseui/icon'
import {StyledLink} from 'baseui/link'
import {ARTWORK_SIZES, ListItem} from 'baseui/list'
import {LabelLarge, ParagraphMedium} from 'baseui/typography'
import Markdown from 'markdown-to-jsx'
import React from 'react'

const MdLink = ({children, ...props}) => <StyledLink {...props}>{children}</StyledLink>

const renderTips = tips =>
  tips.map(tip => (
    <ListItem
      key={tip}
      overrides={{
        ArtworkContainer: {
          style: ({$theme}) => ({
            paddingLeft: $theme.sizing.scale400,
            paddingRight: $theme.sizing.scale400,
            color: $theme.colors.primary500,
          }),
        },
        Content: {
          style: ({$theme}) => ({
            // borderBottom: 'none',
            color: $theme.colors.primary700,
            height: 'auto',
            paddingBottom: $theme.sizing.scale200,
            paddingTop: $theme.sizing.scale200,
          }),
        },
      }}
      artwork={props => <Plus {...props} />}
      artworkSize={ARTWORK_SIZES.MEDIUM}
    >
      <ParagraphMedium
        overrides={{
          Block: {
            style: ({$theme}) => ({
              color: $theme.colors.primary700,
              marginTop: 0,
              marginBottom: 0,
            }),
          },
        }}
      >
        <Markdown
          options={{
            overrides: {
              a: {
                component: MdLink,
                props: {
                  target: '_blank',
                  rel: 'noopener noreferrer',
                },
              },
            },
          }}
        >
          {tip}
        </Markdown>
      </ParagraphMedium>
    </ListItem>
  ))

const StatsTips = ({tips}) => {
  if (tips.length < 1) {
    return null
  }

  return (
    <Block
      overrides={{
        Block: {
          style: ({$theme}) => ({
            paddingBottom: $theme.sizing.scale400,
            paddingLeft: $theme.sizing.scale400,
            paddingRight: $theme.sizing.scale400,
            paddingTop: $theme.sizing.scale400,
            marginBottom: $theme.sizing.scale800,
          }),
        },
      }}
    >
      <LabelLarge
        overrides={{
          Block: {
            style: ({$theme}) => ({
              color: $theme.colors.mono600,
            }),
          },
        }}
      >
        Taking it further
      </LabelLarge>
      <Block
        $as="ul"
        overrides={{
          Block: {
            style: {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              paddingLeft: 0,
            },
          },
        }}
      >
        {renderTips(tips)}
      </Block>
    </Block>
  )
}

export default StatsTips
