import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Block} from 'baseui/block'
import CenteredBlock from '../../components/app/centered-block'
import {DisplayXSmall, LabelLarge, LabelMedium} from 'baseui/typography'
import defaultTo from 'lodash/defaultTo'

const padding = (theme, scale) => ({
  paddingBottom: theme.sizing[scale],
  paddingLeft: theme.sizing[scale],
  paddingRight: theme.sizing[scale],
  paddingTop: theme.sizing[scale],
})

const minutesToDuration = mins => {
  const duration = defaultTo(Math.round(mins), 0)
  const hours = defaultTo(Math.floor(duration / 60), 0)
  const divisorForMinutes = duration % 60
  const minutes = defaultTo(Math.floor(divisorForMinutes), 0)

  return {h: hours, m: minutes}
}

const StatsChallenges = ({challenges, completed, duration}) => {
  const {h, m} = minutesToDuration(duration)
  const formattedTime = `${h}h : ${m}min`

  return (
    <Block
      overrides={{
        Block: {
          style: ({$theme}) => ({
            marginTop: '0px',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: '0px',
            paddingBottom: '0px',
            paddingLeft: $theme.sizing.scale500,
            paddingRight: $theme.sizing.scale500,
            width: `calc(100% - ${$theme.sizing.scale800})`,
            position: 'relative',
            overflow: 'hidden',
            // zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            //...padding($theme, 'scale400'),
          }),
        },
      }}
    >
      <LabelLarge
        overrides={{
          Block: {
            style: ({$theme}) => ({
              color: $theme.colors.mono600,
            }),
          },
        }}
      >
        Stats
      </LabelLarge>
      <Block
        overrides={{
          Block: {
            style: ({$theme}) => ({
              alignItems: 'center',
              display: 'flex',
              ...padding($theme, 'scale500'),
              paddingLeft: 0,
            }),
          },
        }}
      >
        <Block
          overrides={{
            Block: {
              style: ({$theme}) => ({
                color: $theme.colors.primary300,
                ...padding($theme, 'scale400'),
                paddingLeft: 0,
              }),
            },
          }}
        >
          <FontAwesomeIcon icon="puzzle-piece" size="2x" />
        </Block>
        <Block
          overrides={{
            Block: {
              style: ({$theme}) => ({
                paddingLeft: $theme.sizing.scale400,
              }),
            },
          }}
        >
          <DisplayXSmall
            overrides={{
              Block: {
                style: ({$theme}) => ({
                  color: $theme.colors.primary700,
                }),
              },
            }}
          >
            {completed} of {challenges}
          </DisplayXSmall>
          <LabelMedium
            overrides={{
              Block: {
                style: ({$theme}) => ({
                  color: $theme.colors.mono600,
                }),
              },
            }}
          >
            challenges completed
          </LabelMedium>
        </Block>
      </Block>
      <Block
        overrides={{
          Block: {
            style: ({$theme}) => ({
              alignItems: 'center',
              display: 'none', //temp disabled - was flex
              ...padding($theme, 'scale500'),
              paddingLeft: 0,
            }),
          },
        }}
      >
        <Block
          overrides={{
            Block: {
              style: ({$theme}) => ({
                color: $theme.colors.primary300,
                ...padding($theme, 'scale400'),
                paddingLeft: 0,
              }),
            },
          }}
        >
          <FontAwesomeIcon icon="clock" size="2x" />
        </Block>
        <Block
          overrides={{
            Block: {
              style: ({$theme}) => ({
                paddingLeft: $theme.sizing.scale400,
              }),
            },
          }}
        >
          <DisplayXSmall
            overrides={{
              Block: {
                style: ({$theme}) => ({
                  color: $theme.colors.primary700,
                }),
              },
            }}
          >
            {formattedTime}
          </DisplayXSmall>
          <LabelMedium
            overrides={{
              Block: {
                style: ({$theme}) => ({
                  color: $theme.colors.mono600,
                }),
              },
            }}
          >
            spent on this chapter
          </LabelMedium>
        </Block>
      </Block>
    </Block>
  )
}

export default StatsChallenges
