import isString from 'lodash/isString'
import queryString from 'query-string'
import React from 'react'
import {Redirect} from 'react-router-dom'
import {routes} from '../../../utils/config'
import RecoverEmail from './recover-email'
import ResetPassword from './reset-password'
import VerifyEmail from './verify-email'

const Action = props => {
  const params = queryString.parse(props.location.search)

  if (!isString(params.mode) || !isString(params.oobCode)) {
    return <Redirect to={routes.home.url} />
  }

  switch (params.mode) {
    case 'resetPassword':
      // Display reset password handler and UI.
      return <ResetPassword params={params} />
    case 'recoverEmail':
      // Display email recovery handler and UI.
      return <RecoverEmail params={params} />
    case 'verifyEmail':
      // Display email verification handler and UI.
      return <VerifyEmail params={params} />
    default:
      // Error: invalid actionMode.
      return <Redirect to={routes.home.url} />
  }
}

export default Action
