import React from 'react'
import {Block} from 'baseui/block'
import {ProgressBar} from 'baseui/progress-bar'

const StatsProgress = ({progress, successValue}) => {
  const percentage = Math.round((progress / successValue) * 100)

  return (
    <Block
      overrides={{
        Block: {
          style: ({$theme}) => ({
            marginBottom: $theme.sizing.scale800,
            marginTop: $theme.sizing.scale800,
          }),
        },
      }}
    >
      <ProgressBar
        value={progress}
        successValue={successValue}
        getProgressLabel={() => `${'Book 1'}`}
        showLabel
        overrides={{
          BarProgress: {
            style: ({$theme}) => {
              return {
                ...$theme.typography.LabelMedium,
                backgroundColor: $theme.colors.positive,
                color: $theme.colors.mono200,
                position: 'relative',
                ':after': {
                  position: 'absolute',
                  content: percentage > 5 ? `"${percentage}%"` : '',
                  top: $theme.sizing.scale0,
                  right: $theme.sizing.scale400,
                },
              }
            },
          },
          Bar: {
            style: ({$theme}) => ({
              height: $theme.sizing.scale800,
            }),
          },
        }}
      />
    </Block>
  )
}

export default StatsProgress
