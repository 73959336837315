import {Block} from 'baseui/block'
import {Button} from 'baseui/button'
import {Heading, HeadingLevel} from 'baseui/heading'
import {KIND} from 'baseui/notification'
import {ParagraphSmall} from 'baseui/typography'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import LoadingSpinner from '../../../components/app/loading-spinner'
import {AuthLayout} from '../../../components/layout'
import {routes} from '../../../utils/config'
import {setLoading} from '../../persistence/app-slice'
import {auth} from '../../persistence/firebase'
import {setNotification} from '../../persistence/notification-slice'

const RecoverEmail = ({params}) => {
  const dispatch = useDispatch()
  const {loading} = useSelector(state => state.app)
  const [restoredEmail, setRestoredEmail] = useState('')

  useEffect(() => {
    dispatch(setLoading({loading: true}))
    // Confirm the action code is valid.
    auth
      .checkActionCode(params.oobCode)
      .then(actionCodeInfo => {
        // Get the restored email address.
        return setRestoredEmail(actionCodeInfo.data.email)
      })
      .then(() => {
        // Revert to the old email.
        return auth.applyActionCode(params.oobCode)
      })
      .catch(error => {
        // Invalid code.
        dispatch(setNotification({code: error.code, message: error.message, kind: KIND.negative}))
      })
      .finally(() => {
        dispatch(setLoading({loading: false}))
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async event => {
    event.preventDefault()
    dispatch(setLoading({loading: true}))
    try {
      await auth.sendPasswordResetEmail(restoredEmail)
      dispatch(
        setNotification({
          code: 'auth/password-reset-email-sent',
          message: 'Password reset confirmation sent. Please, check your email.',
          kind: KIND.positive,
        }),
      )
      dispatch(setLoading({loading: false}))
    } catch (error) {
      dispatch(setNotification({code: error.code, message: error.message, kind: KIND.negative}))
    }
  }

  if (loading) {
    return (
      <AuthLayout>
        <Block display="flex" alignItems="center" justifyContent="center">
          <LoadingSpinner />
        </Block>
      </AuthLayout>
    )
  }

  if (!restoredEmail) {
    return (
      <AuthLayout>
        <Block $as="header">
          <HeadingLevel>
            <Heading styleLevel={3}>Operation Terminated</Heading>
          </HeadingLevel>
        </Block>
        <Block>
          <ParagraphSmall>
            The action code is invalid. The incident has been reported. Please contact support for
            further assistance.
          </ParagraphSmall>
        </Block>
        <Button
          isLoading={loading}
          disabled={loading}
          $as={Link}
          to={routes.home.url}
          overrides={{
            BaseButton: {
              style: ({$theme}) => ({
                marginTop: $theme.sizing.scale400,
              }),
            },
          }}
        >
          {routes.home.name}
        </Button>
      </AuthLayout>
    )
  }

  return (
    <AuthLayout>
      <Block $as="header">
        <HeadingLevel>
          <Heading styleLevel={3}>Recover Email</Heading>
        </HeadingLevel>
      </Block>
      <Block>
        <ParagraphSmall>
          Account email reverted to {restoredEmail}. If you think your account has been compromised,
          you can reset your password by clicking on the button below.
        </ParagraphSmall>
      </Block>
      <Block $as="form" onSubmit={handleSubmit} noValidate>
        <Button
          isLoading={loading}
          disabled={loading}
          type="submit"
          overrides={{
            BaseButton: {
              style: ({$theme}) => ({
                marginTop: $theme.sizing.scale400,
              }),
            },
          }}
        >
          Send Password Reset Email
        </Button>
      </Block>
    </AuthLayout>
  )
}

export default RecoverEmail
