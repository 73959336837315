import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Block} from 'baseui/block'
import {LabelLarge, LabelSmall} from 'baseui/typography'

const padding = (theme, scale) => ({
  paddingBottom: theme.sizing[scale],
  paddingLeft: theme.sizing[scale],
  paddingRight: theme.sizing[scale],
  paddingTop: theme.sizing[scale],
})

const StatsHeader = ({title, description}) => {
  return (
    <Block
      overrides={{
        Block: {
          style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'stretch',
          },
        },
      }}
    >
      <Block
        overrides={{
          Block: {
            style: {
              alignItems: 'center',
              display: 'flex',
              flex: 1,
            },
          },
        }}
      >
        <Block
          overrides={{
            Block: {
              style: ({$theme}) => ({
                color: $theme.colors.primary700,
                display: 'block',
                ...padding($theme, 'scale500'),
              }),
            },
          }}
        >
          <FontAwesomeIcon icon="book" size="2x" />
        </Block>
        <Block
          overrides={{
            Block: {
              style: ({$theme}) => ({
                flex: 1,
                paddingLeft: $theme.sizing.scale400,
                paddingRight: $theme.sizing.scale400,
                marginLeft: $theme.sizing.scale800,
                [$theme.media.medium]: {
                  marginLeft: 0,
                },
              }),
            },
          }}
        >
          <LabelLarge
            overrides={{
              Block: {
                style: ({$theme}) => ({
                  color: $theme.colors.primary700,
                }),
              },
            }}
          >
            {title}
          </LabelLarge>
          <LabelSmall
            overrides={{
              Block: {
                style: ({$theme}) => ({
                  color: $theme.colors.mono600,
                }),
              },
            }}
          >
            {description}
          </LabelSmall>
        </Block>
        <Block
          overrides={{
            Block: {
              style: ({$theme}) => ({
                color: $theme.colors.primary300,
                display: 'block',
                ...padding($theme, 'scale500'),
              }),
            },
          }}
        >
          <FontAwesomeIcon icon="unlock-alt" size="2x" />
        </Block>
      </Block>
    </Block>
  )
}

export default StatsHeader
