import {Block} from 'baseui/block'
import {Heading, HeadingLevel} from 'baseui/heading'
import {SIZE} from 'baseui/spinner'
import React from 'react'
import Layout from '../layout/base-layout'
import LoadingSpinner from './loading-spinner'

const PageLoading = ({title}) => {
  return (
    <Layout>
      <Block
        $as="main"
        overrides={{
          Block: {
            style: {
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            },
          },
        }}
      >
        <Block $as="header">
          <HeadingLevel>
            <Heading styleLevel={4}>{title}</Heading>
          </HeadingLevel>
        </Block>
        <Block
          overrides={{
            Block: {
              style: {
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
            },
          }}
        >
          <LoadingSpinner $size={SIZE.large} />
        </Block>
      </Block>
    </Layout>
  )
}

export default PageLoading
