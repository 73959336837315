import {KIND} from 'baseui/toast'
import {setLoading} from './app-slice'
import {db} from './firebase'
import {setNotification} from './notification-slice'

export const updateStudentData = (update, doc) => async dispatch => {
  dispatch(setLoading({setLoading: true}))
  try {
    await db
      .collection('enrollments')
      .doc(doc)
      .set({...update}, {merge: true})
    dispatch(setLoading({setLoading: false}))
  } catch (error) {
    dispatch(setNotification({code: error.code, message: error.message, kind: KIND.negative}))
    dispatch(setLoading({setLoading: false}))
  }
}
